import React, { useState, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Link } from "gatsby"
import { Container, Row, Col, Button, Image } from "react-bootstrap"
import { Helmet } from "react-helmet"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import SolutionCard from "../../components/SolutionCard"
// import NewsModal from "../../components/NewsModal" // use when new news needed

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

import "./styles.scss"

import HYMOTS_LOGO from "../../../assets/images/hymots-yatay2.png"
// import NGINRS_LOGO from "../../../assets/images/nginrS.png"
// import NGINRM_LOGO from "../../../assets/images/nginrM.png"
import NGINR_LOGO from "../../../assets/images/nginr.png"
import ETECUBE_LOGO from "../../../assets/images/etecube.png"

import EPHESUS_MINI_LOGO from "../../../assets/images/ephesus_mini.png"
import OIL_RIG_MINI_LOGO from "../../../assets/images/oil_rig_mini.png"
import DATA_SERVICES_MINI_LOGO from "../../../assets/images/data_services_mini.png"
import ETE3_SERVICES_MINI_LOGO from "../../../assets/images/ete3_services_mini.png"
import ETE3_TRAININGS_MINI_LOGO from "../../../assets/images/ete3_trainings_mini.png"
import HYMOTS_FOR_SOLUTIONS from "../../../assets/images/hymots-website-mainPage-1.png"
import CUBES from "../../../assets/images/cubes.png"
import CUBES_LEFT from "../../../assets/images/cubes_left.png"
import CUBES_RIGHT from "../../../assets/images/cubes_right.png"
import NGINR_FOR_SOLUTIONS from "../../../assets/images/nginr-website-mainPage-1.png"
// import MODEL_AND_SIMULATION from "../../../assets/images/archer.png"
import ETECUBE_FOR_SOLUTIONS from "../../../assets/images/etecube-website-mainPage-1.png"

const HomePage = () => {
  const [state] = useGlobalState()
  const [solutionSectionImage, setSolutionSectionImage] = useState(
    NGINR_FOR_SOLUTIONS
  )
  const solutionImages = [
    NGINR_FOR_SOLUTIONS,
    HYMOTS_FOR_SOLUTIONS,
    // MODEL_AND_SIMULATION,
    ETECUBE_FOR_SOLUTIONS,
  ]
  useEffect(() => {
    let indexOfImage = 0
    // eslint-disable-next-line no-undef
    setInterval(() => {
      if (indexOfImage < 3) {
        setSolutionSectionImage(solutionImages[indexOfImage])
        indexOfImage++
      } else {
        indexOfImage = 0
      }
    }, 3000)
  }, [])

  return (
    <>
      <Container fluid className="main-container">
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>ete technology®</title>
          </Helmet>
        </div>
        <Header headerBackgroundColor="#333333" headerTextMode="light" />
        <Container fluid className="image-container">
          <Image className={"image-background"} src={CUBES} />
        </Container>
        <Container fluid className="first-section d-flex align-items-center">
          <Container className="introduction-container">
            <Row className="introduction-row">
              <Col className={"introduction-texts-col col-lg-6 col-10"}>
                <Row>
                  <h2 className="introduction-texts-1">
                    {getLanguageFile(state.language).Home.mainDescriptionTitle}
                  </h2>
                </Row>
                <Row>
                  <h6 className="introduction-texts-2">
                    {getLanguageFile(state.language).Home.mainDescription}
                  </h6>
                </Row>
                <Row>
                  <Link to="/about-us">
                    <Button className="button">
                      {getLanguageFile(state.language).Home.aboutUsButton}
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="second-section">
          <Container>
            <Row style={{ marginBottom: "8rem", marginTop: "8rem" }}>
              <div className="services-text">
                {
                  getLanguageFile(state.language).Home
                    .eteSolutionDescriptionPartOne
                }
              </div>

              <p className="services-text">
                {
                  getLanguageFile(state.language).Home
                    .eteSolutionDescriptionPartTwo
                }
              </p>

              <p className="services-text">
                {
                  getLanguageFile(state.language).Home
                    .eteSolutionDescriptionPartThree
                }
              </p>
            </Row>
          </Container>
        </Container>
        <Container fluid className="third-section">
          <Row>
            <Col className={"background-left-col col-lg-4 d-none d-lg-block"}>
              <Image
                className={"background-img col-lg-12"}
                src={CUBES_LEFT}
              ></Image>
            </Col>
            <Col className={"text-and-button-col col-lg-4 col-12"}>
              <Row className="text-and-button-row">
                {/* <h3 className="text">
                  {getLanguageFile(state.language).Home.contactUsDescription}
                </h3> */}
                <Link to="/contact-us">
                  <Button className="button">
                    {getLanguageFile(state.language).Home.contactUsButton}
                  </Button>
                </Link>
              </Row>
            </Col>
            <Col className={"background-right-col col-lg-4 d-none d-lg-block"}>
              <Image
                className={"background-img col-lg-12"}
                src={CUBES_RIGHT}
              ></Image>
            </Col>
          </Row>
        </Container>
        <Container fluid className="fourth-section">
          <Row className="products-text-row">
            <h3 className="products-text">
              {getLanguageFile(state.language).Home.products}
            </h3>
          </Row>
          <Row className="products-images-row">
            {/* <Col className={"products-images-col  col-lg-3 col-md-6 col-12"}>
              <Link to="/products/nginrS">
                <Image className="products-image" src={NGINRS_LOGO}></Image>
              </Link>
            </Col> */}
            <Col className={"products-images-col  col-lg-3 col-md-6 col-12"}>
              <Link to="/products/nginr">
                <Image className="products-image" src={NGINR_LOGO}></Image>
              </Link>
            </Col>
            <Col className={"products-images-col col-lg-3  col-md-6 col-12"}>
              <Link to="/products/hymots">
                <Image className="products-image" src={HYMOTS_LOGO}></Image>
              </Link>
            </Col>
            <Col className={"products-images-col col-lg-3  col-md-6 col-12"}>
              <Link to="/products/etecube">
                <Image className="products-image" src={ETECUBE_LOGO}></Image>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid className="fifth-section" id={"ETESolutions"}>
          <Row className="fifth-section-heading">
            <h3 className="heading-text">
              {getLanguageFile(state.language).Home.solutions}
            </h3>
            {/* <p className="copy-right">®</p> */}
          </Row>
          <Row className="fifth-section-img-row">
            <Col className={"fifth-section-img-col col-lg-6 col-12"}>
              <Image
                className={"fifth-section-img"}
                src={solutionSectionImage}
              ></Image>
            </Col>
            <Col className={"col-lg-6 col-12 mt-4"}>
              <SolutionCard
                solutionImg={OIL_RIG_MINI_LOGO}
                solutionTitle={getLanguageFile(state.language).Home.ephesus}
                solutionButtonAction={() =>
                  setSolutionSectionImage(NGINR_FOR_SOLUTIONS)
                }
                solutionDescription={
                  getLanguageFile(state.language).Home.ephesusDescription
                }
              />

              <SolutionCard
                solutionImg={EPHESUS_MINI_LOGO}
                solutionTitle={
                  getLanguageFile(state.language).Home.modelingAndSimulation
                }
                solutionButtonAction={() =>
                  setSolutionSectionImage(HYMOTS_FOR_SOLUTIONS)
                }
                solutionDescription={
                  getLanguageFile(state.language).Home
                    .modelingAndSimulationDescription
                }
              />
              <SolutionCard
                solutionImg={ETE3_TRAININGS_MINI_LOGO}
                solutionTitle={
                  getLanguageFile(state.language).Home.eteTrainings
                }
                solutionButtonAction={() =>
                  setSolutionSectionImage(ETECUBE_FOR_SOLUTIONS)
                }
                solutionDescription={
                  getLanguageFile(state.language).Home.eteTrainingsDescription
                }
              />
              <SolutionCard
                solutionImg={DATA_SERVICES_MINI_LOGO}
                solutionTitle={
                  getLanguageFile(state.language).Home.dataServices
                }
                solutionButtonAction={() =>
                  setSolutionSectionImage(ETECUBE_FOR_SOLUTIONS)
                }
                solutionDescription={
                  getLanguageFile(state.language).Home.dataServicesDescription
                }
              />
              <SolutionCard
                solutionImg={ETE3_SERVICES_MINI_LOGO}
                solutionTitle={getLanguageFile(state.language).Home.eteServices}
                solutionButtonAction={() =>
                  setSolutionSectionImage(ETECUBE_FOR_SOLUTIONS)
                }
                solutionDescription={
                  getLanguageFile(state.language).Home.eteServicesDescription
                }
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
      {/* <NewsModal /> */}
    </>
  )
}

export default HomePage
